<template>
  <div class="yellow_bg">
    <div v-if="$lang === 'hr'" class="container pt-5 pb-5">
      <div>
        <h3 class="text-center">Terralogix politika kolačića</h3>
      </div>
      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">1.</div>
          <div class="side-text">Uvod</div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.1</div>
          <div class="side-text">
            <span>
              Naše web-mjesto koristi kolačiće.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.2</div>
          <div class="side-text">
            <span>
              Prilikom prvog posjeta našem web-mjestu , zamolit ćemo Vas za
              privolu za korištenje kolačića u skladu s uvjetima navedenim u
              ovom dokumentu.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">2.</div>
          <div class="side-text">O kolačićima</div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.1</div>
          <div class="side-text">
            <span>
              Kolačić je podatkovna datoteka koja sadrži identificirajuću
              informaciju (niz slova i znamenki) koju Vaš web-preglednik dobiva
              od našeg poslužitelja, a potom ju i sprema. Kolačić se zatim
              ponovno šalje poslužitelju svaki put kada preglednik zatraži
              pristup web-stranici.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.2</div>
          <div class="side-text">
            <span>
              Kolačići mogu biti stalni (spremljeni) ili privremeni (sesijski):
              stalni kolačići bit će pohranjeni u web-pregledniku i ostat će
              aktivnima do roka isteka, osim ako ih korisnik ne izbriše prije
              tog roka; s druge strane, privremeni kolačići isteći će na kraju
              sesije, kada zatvorite web-preglednik.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.3</div>
          <div class="side-text">
            <span>
              Kolačići obično ne sadrže nikakvu informaciju koja bi mogla osobno
              identificirati korisnika, no osobni podaci koje spremamo o Vama
              mogu biti povezani s podacima pribavljenim i spremljenim putem
              kolačića.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.4</div>
          <div class="side-text">
            <span>
              Web-poslužitelji mogu koristiti kolačiće za identifikaciju i
              praćenje korisnika tijekom posjećivanja stranica i ponovno
              prepoznavanje prilikom njihovog povratka na web-mjesto.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">3.</div>
          <div class="side-text">Naši kolačići</div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">3.1</div>
          <div class="side-text">
            Na našoj web-stranici koristimo stalne i privremene kolačiće.
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">3.2</div>
          <div class="side-text">
            <span>
              Kolačiće koristimo u niže navedene svrhe:
            </span>

            <div class="p-cont d-flex">
              <div class="side-num">a)</div>
              <div class="side-text">
                <span>
                  koristimo kolačiće na našem web-mjestu za prepoznavanje
                  računala prilikom korisnikovog posjeta mjestu;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">b)</div>
              <div class="side-text">
                <span>
                  za praćenje korisnika tijekom posjećivanja stranica;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">c)</div>
              <div class="side-text">
                <span>
                  za poboljšanje iskoristivosti web-mjesta;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">d)</div>
              <div class="side-text">
                <span>
                  za analizu korištenja web-mjesta;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">e)</div>
              <div class="side-text">
                <span>
                  za upravljanje web-mjestom;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">f)</div>
              <div class="side-text">
                <span>
                  za sprječavanje prijevara i poboljšanje sigurnosti web-mjesta.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">4.</div>
          <div class="side-text">
            <span>
              Analitički kolačići
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.1</div>
          <div class="side-text">
            <span>
              Koristimo alate Google Analytics i Hotjar za analitiku našeg
              web-mjesta.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.2</div>
          <div class="side-text">
            <span>
              Pružatelji analitičkih usluga koriste statističke i druge podatke
              o korištenju našeg web-mjesta putem kolačića.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.3</div>
          <div class="side-text">
            <span>
              Analitički kolačići koje koristimo na našem web-mjestu su: _utma,
              _utmb, _utmt, _utmc i _utmz.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.4</div>
          <div class="side-text">
            <span>
              Podaci generirani s našeg web-mjesta koriste se za izradu izvješća
              o njegovom korištenju.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.5</div>
          <div class="side-text">
            <span>
              Politika privatnosti pružatelja analitičkih usluga dostupna je na
              poveznici: http://www.google.com/policies/privacy/.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">5.</div>
          <div class="side-text">
            <span>
              Kolačići treće strane
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">5.1</div>
          <div class="side-text">
            <span>
              Naše web-mjesto također koristi kolačiće treće strane.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">5.2</div>
          <div class="side-text">
            <p>
              Koristimo Hotjar za bolje razumijevanje potreba naših korisnika i
              za optimizaciju usluge i korisničkog iskustva. Hotjar je
              tehnološka usluga koja nam pomaže bolje shvatiti iskustva naših
              korisnika (npr. koliko vremena provode na kojim stranicama, koje
              poveznice odabiru i posjećuju, što im se sviđa a što ne itd.), što
              nam omogućuje izgradnju i održavanje usluga na temelju povratnih
              informacija korisnika. Hotjar koristi kolačiće i druge tehnologije
              za prikupljanje podataka o ponašanju korisnika i o njihovim
              uređajima (posebno IP-adrese u anonimiziranom obliku, podatke o
              veličini ekrana uređaja, podatke o vrsti uređaja (posebni
              identifikator uređaja), podatke o pregledniku, geografskoj
              lokaciji (samo država), preferiranom jeziku za prikaz web-mjesta).
              Hotjar sprema ove podatke u pseudomizirani korisnički profil. Ni
              Hotjar ni mi nećemo koristiti ove podatke za identifikaciju
              pojedinačnih korisnika niti za spajanje s drugim podacima o
              pojedinom korisniku. Za detalje, molimo pogledajte Hotjarovu
              politiku privatnosti pritiskom na ovu
              <a
                target="_blank"
                href="https://www.hotjar.com/legal/policies/privacy"
                >poveznicu</a
              >.
            </p>
            <p>
              Možete isključiti mogućnost stvaranja korisničkog profila,
              Hotjarovih spremanja podataka o Vašem korištenju web-mjesta i
              Hotjarovom korištenju pratećih kolačića na drugim web-mjestima
              putem ove
              <a
                target="_blank"
                href="https://www.hotjar.com/legal/compliance/opt-out"
                >poveznice za isključivanje</a
              >.
            </p>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">6.</div>
          <div class="side-text">
            <p class="fw-600">
              Kako mogu promijeniti postavke kolačića?
            </p>
            <p>
              Većina web-preglednika dopušta određenu razinu kontrole na većini
              kolačića putem postavki preglednika. Da biste saznali više o
              kolačićima, uključujući kako vidjeti koji kolačići su postavljeni,
              posjetite stranicu http://www.aboutcookies.org ili
              www.allaboutcookies.org.
            </p>
            <p>
              Saznajte kako upravljati kolačićima na poznatim preglednicima:
            </p>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
                  >Google Chrome</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                  >Microsoft Edge</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                  >Mozilla Firefox</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                  >Microsoft Internet Explorer</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.opera.com/help/tutorials/security/privacy/"
                  >Opera</a
                >
              </li>
              <li>
                <a target="_blank" href="https://support.apple.com/en-gb/safari"
                  >Apple Safari</a
                >
              </li>
            </ul>
            <p>
              Da biste saznali informacije o drugim preglednicima, možete
              posjetiti web-mjesto preglednika.
            </p>
            <p>
              Da biste isključili praćenje Google Analyticsa na svim
              web-mjestima, posjetite http://tools.google.com/dlpage/gaoptout.
            </p>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">7.</div>
          <div class="side-text">
            <span class="fw-600">
              Detalji o nama
            </span>
            <p>
              Terralogix grupu čine različite pravne osobe i druga povezana
              poduzeća, uključujući:
            </p>
            <ul>
              <li>
                Terra Adriatica Solutions d.o.o. (nastupa kao Terra Adriatica),
                tvrtka registrirana u Hrvatskoj, OIB: 83760633400 s
                registriranom adresom: Trg Nikole Šubića Zrinskog 14, 10 000
                Zagreb, Republika Hrvatska.
              </li>
              <li>
                Terralogix Group Ltd – tvrtka registrirana u Engleskoj i Walesu,
                tvrtka # 11736079 s registriranom adresom: Citygate, St. James
                Boulevard, Newcastle Upon Tyne, NE1 4JE, United Kingdom.
              </li>
            </ul>
            <p>
              Ova Politika kolačića izdana je u ime Terralogix Grupe, stoga kada
              spominjemo „Terralogix”, „mi”, „nas” ili „naš” u ovom dokumentu
              upućujemo na odgovarajuću tvrtku iz Terralogix Grupe odgovornu za
              pružanje usluga.
            </p>
            <p>
              Možete nas kontaktirati pismenim putem na gore navedenu adresu,
              putem našeg kontaktnog obrasca na našem web-mjestu ili slanjem
              e-pošte na privacy@terraadriatica.hr.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="$lang === 'es'" class="container pt-5 pb-5">
      <div>
        <h3 class="text-center">Política de Cookies de Terralogix</h3>
      </div>
      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">1.</div>
          <div class="side-text">Introducción</div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.1</div>
          <div class="side-text">
            <span>
              Nuestro sitio web utiliza cookies.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.2</div>
          <div class="side-text">
            <span>
              Le pediremos su consentimiento para el uso de cookies de acuerdo
              con los términos de esta política cuando visite nuestro sitio web
              por primera vez.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">2.</div>
          <div class="side-text">Acerca de las cookies</div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.1</div>
          <div class="side-text">
            <span>
              Una cookie es un archivo que contiene un identificador (una cadena
              de letras y números) que un servidor web envía a un navegador web
              y el navegador la almacena. El identificador se envía de vuelta al
              servidor cada vez que el navegador solicita una página del
              servidor.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.2</div>
          <div class="side-text">
            <span>
              Las cookies pueden ser cookies persistentes o cookies de sesión.
              Una cookie persistente será almacenada por un navegador web y
              permanecerá válida hasta su fijada fecha de caducidad, a menos que
              el usuario la elimine antes de la fecha de caducidad. Por otro
              lado, una cookie de sesión caducará al final de la sesión de
              usuario, al cerrar el navegador web.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.3</div>
          <div class="side-text">
            <span>
              Las cookies no suelen contener ninguna información que identifique
              personalmente a un usuario, pero la información personal que
              almacenamos sobre usted puede estar vinculada a la información
              almacenada en las cookies y obtenida a través de ellas.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.4</div>
          <div class="side-text">
            <span>
              Los servidores web pueden usar cookies para identificar y rastrear
              a los usuarios mientras navegan por diferentes páginas de un sitio
              web e identificar a los usuarios que vuelvan a un sitio web.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">3.</div>
          <div class="side-text">Nuestras cookies</div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">3.1</div>
          <div class="side-text">
            En nuestro sitio web podemos utilizar tanto cookies de sesión como
            las persistentes.
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">3.2</div>
          <div class="side-text">
            <span>
              Los fines para los que se utilizan se enumeran a continuación:
            </span>

            <div class="p-cont d-flex">
              <div class="side-num">a)</div>
              <div class="side-text">
                <span>
                  utilizamos cookies en nuestro sitio web para reconocer una
                  computadora cuando un usuario visita el sitio web;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">b)</div>
              <div class="side-text">
                <span>
                  rastrear a los usuarios mientras navegan por el sitio web;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">c)</div>
              <div class="side-text">
                <span>
                  mejorar el funcionamiento del sitio web;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">d)</div>
              <div class="side-text">
                <span>
                  analizar el uso del sitio web;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">e)</div>
              <div class="side-text">
                <span>
                  administrar el sitio web;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">f)</div>
              <div class="side-text">
                <span>
                  prevenir fraudes y mejorar la seguridad del sitio web.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">4.</div>
          <div class="side-text">
            <span>
              Cookies de Google Analytics
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.1</div>
          <div class="side-text">
            <span>
              Utilizamos Google Analytics y Hotjar para analizar el uso de
              nuestro sitio web.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.2</div>
          <div class="side-text">
            <span>
              Nuestro proveedor de servicios analíticos genera información
              estadística y de otros tipos sobre el uso del sitio web mediante
              cookies.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.3</div>
          <div class="side-text">
            <span>
              Las cookies analíticas utilizadas por nuestro sitio web llevan los
              siguientes nombres: _utma, _utmb, _utmt, _utmc y _utmz
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.4</div>
          <div class="side-text">
            <span>
              La información generada en relación con nuestro sitio web se
              utiliza para crear informes sobre el uso de nuestro sitio web.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.5</div>
          <div class="side-text">
            <span>
              La política de privacidad de nuestro proveedor de servicios de
              análisis está disponible en:
              http://www.google.com/policies/privacy/.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">5.</div>
          <div class="side-text">
            <span>
              Cookies de terceros
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">5.1</div>
          <div class="side-text">
            <span>
              Nuestro sitio web también utiliza cookies de terceros.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">5.2</div>
          <div class="side-text">
            <p>
              Utilizamos Hotjar para comprender mejor las necesidades de
              nuestros usuarios y optimizar este servicio y experiencia. Hotjar
              es un servicio de tecnología que nos ayuda a comprender mejor la
              experiencia de nuestros usuarios (por ejemplo, cuánto tiempo pasan
              en qué páginas, en qué enlaces eligen hacer clic, qué les gusta y
              qué no les gusta a los usuarios, etc.) y esto nos permite
              construir y mantener nuestro servicio con retroalimentación de los
              usuarios. Hotjar utiliza cookies y otras tecnologías para
              recopilar datos sobre el comportamiento de nuestros usuarios y sus
              dispositivos (en particular, la dirección IP del dispositivo
              (capturada y almacenada solo de forma anonimizada), tamaño de
              pantalla del dispositivo, tipo de dispositivo (identificador único
              de dispositivo), información del navegador, ubicación geográfica
              (solo país), idioma preferido para mostrar nuestro sitio web).
              Hotjar almacena esta información en un perfil seudonimizado de
              usuario. Ni Hotjar ni nosotros utilizaremos esta información para
              identificar usuarios individuales o para cotejarla con otros datos
              sobre un usuario individual. Para más detalles, consulte por favor
              la política de privacidad de Hotjar haciendo clic en
              <a href="https://www.hotjar.com/legal/policies/privacy"
                >este enlace</a
              >.
            </p>
            <p>
              Si lo desea, usted puede excluirse de la creación de un perfil de
              usuario, el almacenamiento de datos de Hotjar sobre su uso de
              nuestro sitio web y el uso de cookies de seguimiento por parte de
              Hotjar en otros sitios web haciendo clic en este
              <a href="https://www.hotjar.com/legal/compliance/opt-out"
                >enlace de exclusión voluntaria</a
              >.
            </p>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">6.</div>
          <div class="side-text">
            <p class="fw-600">
              ¿Cómo puedo cambiar mis ajustes de cookies?
            </p>
            <p>
              La mayoría de los navegadores web permiten cierto control sobre la
              mayoría de las cookies a través de los ajustes del navegador. Para
              obtener más información sobre las cookies, incluido cómo ver qué
              cookies están establecidas, visite http://www.aboutcookies.org o
              www.allaboutcookies.org.
            </p>
            <p>
              Descubra cómo gestionar las cookies en navegadores populares:
            </p>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
                  >Google Chrome</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                  >Microsoft Edge</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                  >Mozilla Firefox</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                  >Microsoft Internet Explorer</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.opera.com/help/tutorials/security/privacy/"
                  >Opera</a
                >
              </li>
              <li>
                <a target="_blank" href="https://support.apple.com/en-gb/safari"
                  >Apple Safari</a
                >
              </li>
            </ul>
            <p>
              Para encontrar información relacionada con otros navegadores,
              visite el sitio web del desarrollador del navegador en cuestión.
            </p>
            <p>
              Para optar por no ser rastreado por Google Analytics en ninguno de
              los sitios web, visite http://tools.google.com/dlpage/gaoptout.
            </p>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">7.</div>
          <div class="side-text">
            <span class="fw-600">
              Sobre nosotros
            </span>
            <p>
              El Grupo Terralogix consta de varias personas jurídicas y empresas
              afiliadas, que incluyen:
            </p>
            <ul>
              <li>
                Terra Adriatica Solutions d.o.o. (opera bajo el nombre comercial
                de Terra Adriatica), una compañía registrada en Croacia, NIF
                (OIB): 83760633400 con domicilio social en Trg Nikole Šubića
                Zrinskog 14, 10000 Zagreb, Croacia;
              </li>
              <li>
                Terralogix Group Ltd – una compañía registrada en Inglaterra y
                Gales, compañía n.º 11736079 con domicilio social en Citygate,
                St. James Boulevard, Newcastle, NE1 4JE, Reino Unido.
              </li>
            </ul>
            <p>
              Esta Política de Cookies se emite en nombre del Grupo Terralogix,
              por lo que cuando mencionamos “Terralogix”, “nosotros”, “nos” o
              “nuestro” en estos Términos y Condiciones, nos referimos a la
              compañía relevante del Grupo Terralogix responsable de la
              prestación de Servicios.
            </p>
            <p>
              Puede ponerse en contacto con nosotros escribiendo a la dirección
              profesional indicada anteriormente, mediante el formulario de
              contacto en nuestro sitio web o por correo electrónico a
              privacy@terraadriatica.hr.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container pt-5 pb-5">
      <div>
        <h3 class="text-center">Terralogix Cookie Policy</h3>
      </div>
      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">1.</div>
          <div class="side-text">Introduction</div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.1</div>
          <div class="side-text">
            <span>
              Our website uses cookies.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.2</div>
          <div class="side-text">
            <span>
              We will ask you to consent to our use of cookies in accordance
              with the terms of this policy when you first visit our website.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">2.</div>
          <div class="side-text">About cookies</div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.1</div>
          <div class="side-text">
            <span>
              A cookie is a file containing an identifier (a string of letters
              and numbers) that is sent by a web server to a web browser and is
              stored by the browser. The identifier is then sent back to the
              server each time the browser requests a page from the server.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.2</div>
          <div class="side-text">
            <span>
              Cookies may be either "persistent" cookies or "session" cookies: a
              persistent cookie will be stored by a web browser and will remain
              valid until its set expiry date, unless deleted by the user before
              the expiry date; a session cookie, on the other hand, will expire
              at the end of the user session, when the web browser is closed.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.3</div>
          <div class="side-text">
            <span>
              Cookies do not typically contain any information that personally
              identifies a user, but personal information that we store about
              you may be linked to the information stored in and obtained from
              cookies.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.4</div>
          <div class="side-text">
            <span>
              Cookies can be used by web servers to identity and track users as
              they navigate different pages on a website and identify users
              returning to a website.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">3.</div>
          <div class="side-text">Our cookies</div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">3.1</div>
          <div class="side-text">
            We may use both session and persistent cookies on our website.
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">3.2</div>
          <div class="side-text">
            <span>
              The purposes for which they are used, are set out below:
            </span>

            <div class="p-cont d-flex">
              <div class="side-num">a)</div>
              <div class="side-text">
                <span>
                  we use cookies on our website to recognise a computer when a
                  user visits the website;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">b)</div>
              <div class="side-text">
                <span>
                  track users as they navigate the website;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">c)</div>
              <div class="side-text">
                <span>
                  improve the website's usability;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">d)</div>
              <div class="side-text">
                <span>
                  analyse the use of the website;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">e)</div>
              <div class="side-text">
                <span>
                  administer the website;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">f)</div>
              <div class="side-text">
                <span>
                  prevent fraud and improve the security of the website.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">4.</div>
          <div class="side-text">
            <span>
              Analytics cookies
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.1</div>
          <div class="side-text">
            <span>
              We use Google Analytics and Hotjar to analyse the use of our
              website.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.2</div>
          <div class="side-text">
            <span>
              Our analytics service provider generates statistical and other
              information about website use by means of cookies.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.3</div>
          <div class="side-text">
            <span>
              The analytics cookies used by our website have the following
              names: _utma, _utmb, _utmt, _utmc and _utmz
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.4</div>
          <div class="side-text">
            <span>
              The information generated relating to our website is used to
              create reports about the use of our website.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.5</div>
          <div class="side-text">
            <span>
              Our analytics service provider's privacy policy is available at:
              http://www.google.com/policies/privacy/.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">5.</div>
          <div class="side-text">
            <span>
              Third party cookies
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">5.1</div>
          <div class="side-text">
            <span>
              Our website also uses third party cookies.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">5.2</div>
          <div class="side-text">
            <p>
              We use Hotjar in order to better understand our users’ needs and
              to optimize this service and experience. Hotjar is a technology
              service that helps us better understand our users experience (e.g.
              how much time they spend on which pages, which links they choose
              to click, what users do and don’t like, etc.) and this enables us
              to build and maintain our service with user feedback. Hotjar uses
              cookies and other technologies to collect data on our users’
              behavior and their devices (in particular device's IP address
              (captured and stored only in anonymized form), device screen size,
              device type (unique device identifiers), browser information,
              geographic location (country only), preferred language used to
              display our website). Hotjar stores this information in a
              pseudonymized user profile. Neither Hotjar nor we will ever use
              this information to identify individual users or to match it with
              further data on an individual user. For further details, please
              see Hotjar’s privacy policy by clicking on
              <a href="https://www.hotjar.com/legal/policies/privacy"
                >this link</a
              >.
            </p>
            <p>
              You can opt-out to the creation of a user profile, Hotjar’s
              storing of data about your usage of our site and Hotjar’s use of
              tracking cookies on other websites by following this
              <a href="https://www.hotjar.com/legal/compliance/opt-out"
                >opt-out link</a
              >.
            </p>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">6.</div>
          <div class="side-text">
            <p class="fw-600">
              How do I change my cookie settings?
            </p>
            <p>
              Most web browsers allow some control of most cookies through the
              browser settings. To find out more about cookies, including how to
              see what cookies have been set, visit http://www.aboutcookies.org
              or www.allaboutcookies.org.
            </p>
            <p>
              Find out how to manage cookies on popular browsers:
            </p>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
                  >Google Chrome</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                  >Microsoft Edge</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                  >Mozilla Firefox</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                  >Microsoft Internet Explorer</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.opera.com/help/tutorials/security/privacy/"
                  >Opera</a
                >
              </li>
              <li>
                <a target="_blank" href="https://support.apple.com/en-gb/safari"
                  >Apple Safari</a
                >
              </li>
            </ul>
            <p>
              To find information relating to other browsers, visit the browser
              developer's website.
            </p>
            <p>
              To opt out of being tracked by Google Analytics across all
              websites, visit http://tools.google.com/dlpage/gaoptout.
            </p>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">7.</div>
          <div class="side-text">
            <span class="fw-600">
              Our details
            </span>
            <p>
              The Terralogix Group is made up of different legal entities and
              other affiliates, including:
            </p>
            <ul>
              <li>
                Terra Adriatica Solutions d.o.o. (trading as Terra Adriatica), A
                company registered in Croatia, OIB: 83760633400 with registered
                address: Trg Nikole Šubića Zrinskog 14, 10 000 Zagreb, Republika
                Hrvatska;
              </li>
              <li>
                Terralogix Group Ltd – a company registered in England and
                Wales, Company # 11736079 with registered address: Citygate, St.
                James Boulevard, Newcastle, NE1 4JE, United Kingdom.
              </li>
            </ul>
            <p>
              This Cookie Policy is issued on behalf of the Terralogix Group so
              when we mention “Terralogix,” "we," "us" or "our" in these Terms
              and Conditions, we are referring to the relevant company in the
              Terralogix Group responsible for providing Services.
            </p>
            <p>
              You can contact us by writing to the business address given above,
              by using our website contact form, by email to
              privacy@terraadriatica.hr.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CookiePolicy"
};
</script>

<style scoped>
.p-cont {
  margin-bottom: 10px;
}

.side-num {
  width: 70px;
}

.side-text {
  width: calc(100% - 70px);
}
</style>
